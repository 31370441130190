
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { formatDate } from "@/utils/formatters";
import { activityIsDossier } from "@/utils/model-helpers";
import { parse } from "fecha";
import type { IndexTaskPayload, Task } from "@/store/modules/task.store";
import type { IndexAppointmentPayload } from "@/store/modules/appointment.store";

@Component
export default class DossierPartNextTask extends Vue {
    @Action("task/index") indexTasks!: (payload: IndexTaskPayload) => Promise<Task[]>;

    @Action("appointment/index") indexAppointments!: (payload?: IndexAppointmentPayload) => Promise<Appointment[]>;

    @Getter("activity/viewing") activity!: Activity;

    formatDate = formatDate;

    task: Task | null = null;

    appointment: Appointment | null = null;

    activity_id = Number(this.$route.params.id);

    loading: boolean = false;

    get isDossier() {
        return activityIsDossier(this.activity);
    }

    get appointmentAction() {
        return this.activity.actions.find((a) => a.type === "appointment" && parse(a.deadline, "isoDateTime")! >= new Date());
    }
}
